import React from 'react'
import 'normalize.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import styled from 'styled-components'
import { GlobalStyles } from '../styles/GlobalStyles'
import Typography from '../styles/Typography'
import Header from './Header'
import FooterStatic from './FooterStatic'
import Footer from './Footer'

const LayoutStyles = styled.div`
  position: relative;
  min-height: 100vh;
  height: 100%;
`

const MainStyles = styled.main`
  padding-bottom: 144px;
  max-width: 1024px;
  margin: 0 auto;
`

const Layout = ({ children }) => (
  <LayoutStyles>
    <GlobalStyles />
    <Typography />
    <Header logoFill="#ffb259" />
    <MainStyles>{children}</MainStyles>
    <FooterStatic />
    <Footer />
  </LayoutStyles>
)

export default Layout
