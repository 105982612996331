import React from 'react'
import { slide as SlideMenu } from 'react-burger-menu'
import { Link } from 'gatsby'
import styled from 'styled-components'

// eslint-disable-next-line react/jsx-props-no-spreading
const LinkStyles = styled((props) => <Link {...props} />)`
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: var(--paleGray);
  text-decoration: none;
  outline: none;
  margin: var(--spacing) 0;
  padding-bottom: 1.125rem;
`

const styles = {
  bmBurgerButton: {
    position: 'fixed',
    width: '34px',
    height: '30px',
    right: '24px',
    top: '24px',
  },
  bmBurgerBars: {
    background: '#ffb259',
    borderRadius: '3px',
  },
  bmBurgerBarsHover: {
    background: '#ffb259',
  },
  bmCrossButton: {
    height: '34px',
    width: '34px',
    top: '1.5rem',
    right: '1.5rem',
  },
  bmCross: {
    background: '#ffb259',
    borderRadius: '3px',
    height: '34px',
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
    width: '100vw',
    maxWidth: '1024px',
    top: '0',
    zIndex: '1000',
  },
  bmMenu: {
    background: '#003a52',
    padding: '0',
    fontSize: '1.5rem',
  },
  bmMorphShape: {
    fill: '#003a52',
  },
  bmItemList: {
    color: '#f2f2f2',
    padding: '107px 0 0 0',
    zIndex: '1000',
  },
  bmItem: {
    display: 'block',
    borderBottom: '1px solid #a1ded2',
    padding: '1rem 3rem',
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)',
    top: '0',
    zIndex: '1000',
  },
}

const Menu = (closeMenu = () => null) => (
  <SlideMenu right styles={styles}>
    <LinkStyles to="/" onClick={() => closeMenu()}>
      Home
    </LinkStyles>
    <LinkStyles to="/tours" onClick={() => closeMenu()}>
      Boat Tours
    </LinkStyles>
    <LinkStyles to="/taxi" onClick={() => closeMenu()}>
      Water Taxi
    </LinkStyles>
    <LinkStyles to="/van" onClick={() => closeMenu()}>
      Van Rental
    </LinkStyles>
    <LinkStyles to="/about" onClick={() => closeMenu()}>
      About Bird's Eye
    </LinkStyles>
    <LinkStyles to="/contact" onClick={() => closeMenu()}>
      Contact
    </LinkStyles>
  </SlideMenu>
)

export default Menu
