import styled, { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  :root {
    --white: #fff;
    --gray: #e5e5e5;
    --paleGray: #f2f2f2;
    --darkBlue: #003a52;
    --teal: #a1ded2;
    --yellow: #ffb259;
    --spacing: 1.5rem;
    --headerHeight: 75px;
    --footerHeight: 50px;
    --footerStaticHeight: 94px;
  }

  html {
    font-size: 16px;
  }

  body {
    margin: 0;
    padding: 0;
    font-size: 1rem;
    background-color: var(--paleGray);
  }
  
  .gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }

  img {
    max-width: 100%;
  }

  .sr-only:not(:focus):not(:active) {
    clip: rect(0 0 0 0); 
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap; 
    width: 1px;
  }
`

export const HeroStyles = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  justify-items: center;
  align-items: center;
  height: calc(100% - 75px);
  padding: var(--spacing);
  text-align: center;

  h1,
  h2 {
    color: var(--paleGray);
    font-weight: 700;
    font-size: 2.25rem;
    margin: 0.5rem 0;

    @media screen and (min-width: 500px) {
      font-size: 3.25rem;
    }
  }

  h1 {
    margin-top: var(--spacing);
  }

  .logo {
    height: 10.25rem;

    @media screen and (min-width: 500px) {
      height: 12.25rem;
    }
  }

  p {
    color: var(--paleGray);
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.375;
    margin: 1rem 0;

    @media screen and (min-width: 500px) {
      font-size: 1.5rem;
      margin: var(--spacing) 0;
    }
  }

  .ctas {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0;

    a:nth-of-type(odd) {
      justify-self: end;
    }

    a:nth-of-type(even) {
      justify-self: start;
    }
  }

  a {
    font-size: 1rem;
    padding: 0.75rem 1rem;
    margin: 0.75rem;
    min-width: 6rem;

    @media screen and (min-width: 500px) {
      padding: 0.75rem 2rem;
      margin: 0.75rem 1rem;
      min-width: 8rem;
    }
  }
`

export const HrStyles = styled.hr`
  margin: var(--spacing) 0;
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 57, 82, 0.7), rgba(0, 0, 0, 0));
`
