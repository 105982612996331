import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Menu from './Menu'
import Logo from '../assets/images/bird.svg'

const HeaderStyles = styled.header`
  height: var(--headerHeight);
  width: 100vw;
  position: fixed;
  top: 0;
  background-color: rgba(0, 57, 82, 0.5);
  z-index: 500;
`

const LogoStyles = styled.div`
  margin-left: var(--spacing);
  margin-top: var(--spacing);
`

const Header = ({ logoFill }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const closeMenu = () => {
    setIsMenuOpen(false)
  }

  return (
    <HeaderStyles>
      <LogoStyles>
        <Link to="/">
          <Logo fill={logoFill} />
        </Link>
      </LogoStyles>
      <Menu closeMenu={closeMenu} />
    </HeaderStyles>
  )
}

export default Header
