import React from 'react'
import styled from 'styled-components'
import Phone from '../assets/images/phone.svg'
import Instagram from '../assets/images/instagram.svg'
import Email from '../assets/images/email.svg'

const FooterStyles = styled.footer`
  background-color: var(--darkBlue);
  color: var(--teal);
  padding: var(--spacing);
  position: absolute;
  bottom: var(--footerHeight);
  width: calc(100vw - 3rem);
  text-align: center;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--spacing);
  }

  svg {
    height: 20px;
  }

  p {
    margin: 0.5rem 0 0 0;
    font-size: 0.75rem;
  }
`

const FooterStatic = () => (
  <FooterStyles>
    <div>
      <a href="tel:+1-207-632-9369" rel="noreferrer nofollow">
        <Phone fill="#a1ded2" />
      </a>
      <a href="https://www.instagram.com/birdseye_maine" rel="nofollow noopener noreferrer" target="_blank">
        <Instagram fill="#a1ded2" />
      </a>
      <a href="mailto:info@birdseyemaine.com" rel="noreferrer nofollow">
        <Email fill="#a1ded2" />
      </a>
    </div>
    <p>&copy; {new Date().getFullYear()} Bird's Eye Maine</p>
  </FooterStyles>
)

export default FooterStatic
