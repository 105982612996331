import React from 'react'
import styled from 'styled-components'
import Phone from '../assets/images/phone.svg'

const FooterStyles = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  width: 100vw;
  z-index: 50;
  background-color: var(--teal);
  color: var(--darkBlue);
  font-size: 1.125rem;
  text-align: center;
  height: var(--footerHeight);
  line-height: var(--spacing);

  > div {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    justify-items: center;
    align-items: center;
    position: absolute;
    right: 0;
    bottom: var(--footerHeight);
    width: 95px;
    height: 95px;
    background-color: var(--teal);
    border-top-left-radius: 16px;

    > a {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      justify-items: center;
      align-items: center;
      height: 45px;
      width: 45px;
      border-radius: 50%;
      background-color: var(--darkBlue);

      &:hover,
      &:focus {
        opacity: 0.5;
      }
    }

    svg {
      height: 30px;
      width: 22px;
    }
  }

  > a {
    text-decoration: none;
  }
`

const Footer = () => (
  <FooterStyles>
    <div>
      <a href="tel:+1-207-632-9369" rel="noreferrer nofollow">
        <Phone fill="#a1ded2" />
      </a>
    </div>
    <a href="tel:+1-207-632-9369" rel="noreferrer nofollow">
      Call us for bookings and questions!
    </a>
  </FooterStyles>
)

export default Footer
