import { createGlobalStyle } from 'styled-components'

const Typography = createGlobalStyle`
  html {
    font-family: 'Montserrat', sans-serif;
    color: var(--darkBlue);
  }

  p, li {
    letter-spacing: 0.5px;
    line-height: 1.5;
    margin: 1rem 0;
  }

  h1,h2,h3,h4,h5,h6 {
    font-weight: normal;
    margin: 0;
  }

  a {
    color: var(--darkBlue);
  }
`

export default Typography
